import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import "../Logistics/Logistics.scss";
import ServiceToolbar from "../ServiceToolbar";

const ItServices = () => {
  return (
    <>
      <div className="logistics-con">
     <div>
      <p className="title">
        <StaticImage
          className="service-badge"
          src="../../../images/svg/it-services.svg"
          width={45}
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="badge"
        />
        It Services
        </p>
     </div>

        <div className="d-flex flex-row service-ch-land">
          <div className="logistics-left">
            <div className="logistics">
              <div className="logo-con">
               <StaticImage
                  className="ch-img"
                  src="../../../images/aesi.png"
                  width={400}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                />
              </div>

              <div className="text">
                {/* <p className="label">Stevedoring & Arrastre</p> */}
                <p>
                    AESi Automation Solutions is brought to you
                    by Accur8 Enterprise Solutions Inc. that
                    automates and improves the operational efficiency
                    of your port, terminal, cargo handling, warehouse,
                    inventory tracking, payroll, and accounting.
                </p>
                <br/>
                <p>Visit <a href="https://www.aesiph.com" target="_blank">https://www.aesiph.com/</a></p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <ServiceToolbar />
    </>
  );
};

export default ItServices;
